.header {
  border-bottom: solid 1px #e2e2e7;
}

.navbar {
  background-color: var(--color-navbar-bg);
  color: var(--color-navbar-text);
  font-size: 0.83333rem;
  word-wrap: break-word;
  z-index: var(--z-index-navbar);
}

.navbar a {
  text-decoration: none;
}

.navbar-brand .navbar-item:first-child,
.navbar-brand .navbar-item:first-child a {
  color: var(--color-navbar-text);
  font-size: 1.15rem;
}

.navbar-brand .separator {
  padding: 0 0.375rem;
}

.navbar-burger {
  color: var(--color-navbar-text);
  background: none;
  border: none;
  outline: none;
}

.navbar-burger::-moz-focus-inner {
  border: none;
}

.navbar-item {
  color: var(--color-text);
  padding: 0.5rem;
  font-family: var(--heading);
  font-weight: 500;
  font-size: 0.9rem;
}

.navbar-item:hover {
  color: var(--color-navbar-hover);
}

.navbar-button{
  color: white;
  padding: 6px 14px;
  font-family: var(--heading);
  font-weight: 500;
  font-size: 0.9rem;
  display: inline-block;
  border-radius: 100px;
  letter-spacing: -0.02em;
  transition: all 0.2s ease;
  background: #4F56FA;
  text-decoration: none;
  text-transform: none;
  border: 1px solid #EFEFEF;
  text-align: center;
  white-space: nowrap;
  text-wrap: nowrap;
}

.navbar-button:hover {
  background: #4349D4!important;
  color: white;
}

.navbar-search-input {
  font-size: 1rem;
  background-color: var(--aluminum-1);
  border-radius: 0.25rem;
  border: 1px solid var(--aluminum-3);

  &:focus {
    outline: none;
  }
}

.navbar-search-checkbox {
  display: none;
}

@media screen and (min-width: $mobile-breakpoint) {
  .navbar-burger {
    display: none;
  }
}
