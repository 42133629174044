.article {
  padding-bottom: var(--lg); /* matches nav padding */

  .listingblock {
    position: relative;

    .btn-copy {
      visibility: hidden;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: var(--border-radius);
    }

    &:hover .btn-copy {
      visibility: visible;
    }
  }

  .listingblock.nopadding {
    pre code {
      padding: 0;
    }

    .btn-copy {
      padding: 0;
    }
  }

  pre code {
    display: block;
    overflow-x: auto;
    padding: 1em;
    border-radius: var(--border-radius);
    max-width: 90vw;
  }

  img {
    max-width: 100%;
  }

  aside {
    background: var(--aluminum-1);
    border: 1px solid var(--aluminum-3);
    border-radius: 2px;
    color: var(--steel-1);
    font-size: 14px;
    padding: var(--md) var(--lg) var(--sm);

    &::before {
      background-image: url(../images/icons/note.svg);
      background-position: left 40%;
      background-repeat: no-repeat;
      background-size: 18px;
      color: var(--steel-5);
      content: "Note";
      display: block;
      font-size: 16px;
      font-weight: var(--weight-medium);
      opacity: 0.7;
      padding-left: 20px;
    }
  }

  code {
    &.primary {
      color: #4e4e67;
    }
    &.secondary {
      color: #ab49d6;
    }
    &.light {
      color: #4f4f68;
    }
  }
}

.heading-link, .anchor {
  float: right;
  visibility: hidden;
  color: inherit;
  font-size: .8em;
  margin-top: .25em;
  margin-left: .2em;

  &:focus,
  h1:hover &,
  h2:hover &,
  h3:hover &,
  h4:hover &,
  h5:hover &,
  h6:hover & {
    visibility: visible;
  }

  .contract & {
    visibility: visible;
  }
}

.anchor {
  padding: 0 .3em;
  text-decoration: none;

  &::before {
    content: '#'
  }
}

.contract h3 code {
  padding: 0;
  color: inherit;
  background-color: transparent;
}

.contract-index {
  border-radius: var(--border-radius);
  max-width: 90vw;
  padding: 1.5rem;
  background-color: #f8f8fa;
  margin-top: 1em;
  margin-bottom: 1em;
  word-wrap: break-word;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      pre,
      code {
        padding: 0;
        color: inherit;
        line-height: 1rem;
        background-color: transparent;
      }
    }
  }

  .title {
    font-family: var(--heading);
    font-size: 0.9em;
    color: var(--core-violet-2);
    letter-spacing: .05em;
    font-weight: 600;
    text-transform: uppercase;
  }

  .ulist {
    border-top: solid 1px var(--aluminum-4);
    margin-top: 1.2em;
    padding-top: .25em;

    > .title {
      float: right;
      color: var(--color-text);
      margin-top: .8em;
      font-size: 0.8em;
    }
  }
}

.contract-subindex-inherited {
  opacity: .7;
}

.contract-item {
  border-radius: var(--border-radius);
  border: solid 1px #ddd;
  max-width: 90vw;
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  $spacing: 1em;
  word-wrap: break-word;

  > * {
    margin-right: $spacing;
    margin-left: $spacing;
  }

  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    padding: 0;
    margin: $spacing;
    margin-bottom: 2 * $spacing;
    position: relative;
    font-size: inherit;

    code {
      flex-grow: 1;
      color: inherit;
      font-weight: var(--weight-normal);
      background-color: transparent;
      padding: 0;

      .contract-item-name {
        font-weight: var(--weight-medium);
      }
    }

    .anchor {
      order: 10;
      font-size: var(--md);
      font-weight: normal;
      margin-top: 0;
    }

    .item-kind {
      font-family: var(--sans-serif);
      font-size: var(--md);
      font-weight: 300;
      opacity: .8;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      box-sizing: content-box;
      padding: $spacing;
      margin: -$spacing;
      z-index: -10;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      background-color: #f9f9fa;
      border-bottom: solid 1px #ddd;
    }
  }

  .admonitionblock {
    font-family: var(--sans-serif);
    margin-right: $spacing;
    margin-left: $spacing;
  }
}

.article-navigation {
  display: flex;

  div {
    display: block;
    flex-grow: 1;
    font-weight: var(--weight-bold);
    color: var(--core-violet-4);
    margin-top: 1.5rem;
    margin-bottom: .5rem;

    a {
      text-decoration: none;
      text-transform: uppercase;
      font-size: var(--md);
    }

    &.btn-next { text-align: right; }
  }
}

.article-banner {
  background-color: var(--aluminum-2);
  border: solid 1px var(--aluminum-4);
  border-radius: var(--border-radius);
  padding: 10px;
  margin: 0 -10px;
}
