.nav {
  padding: 1rem;
  background: var(--core-gray);
  color: var(--color-text-light);

  a {
    text-decoration: none;
    color: inherit;
  }

  .nav-li-active > a {
    color: var(--core-violet-4);
  }
}

.nav-component {
  line-height: 1rem;
}

.nav-component a,
.btn-back {
  padding: .7rem 1rem;
}

.nav-title {
  padding: 0 1rem;
  font-size: .8em;
  font-family: var(--heading);
  color: var(--aluminum-5);
  letter-spacing: .05em;
  font-weight: var(--weight-bold);
  text-transform: uppercase;
}

.btn-back {
  opacity: .6;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
  margin-left: 1.3rem;
  cursor: pointer;

  &::before{
    display: inline-block;
    content: url(../images/icons/arrow_back.svg);
    margin-right: 0.3em;
    margin-left: -1.3rem;
    width: 1rem;
  }
}

.nav-heading {
  font-size: 1.3rem;
  font-weight: var(--weight-medium);
  font-family: var(--heading);
  padding: 0 1rem;
}

.nav-component-active {
  .nav-heading-active {
    padding-top: 15px;
    padding-bottom: 4px;
    background: var(--core-gray);
    z-index: 2;
  }
}

.nav-list {
  padding-left: 0;
  list-style: none;
  line-height: 1.7em;
}

.nav-component-heading {
  color: var(--steel-4);
  font-family: var(--heading);
  font-weight: var(--weight-medium);
  position: relative;
  font-size: 1rem;
}

.nav-link:hover {
  opacity: .8;
  cursor: pointer;
}

.nav-link code {
  font-size: .9rem;
  padding: 0;
}

.nav-icon {
  opacity: .8;
}

button.collapse-toggle {
  cursor: pointer;
  padding: 0;
  border: none;

  &.toggled {
    img {
      transform: rotate(-90deg);
    }

    ~ .collapsible-content {
      display: none;
    }
  }
}

.close-menu-btn {
  border: 0;
}
