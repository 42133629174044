/* grid layout */

.card-section .sectionbody,
.card-section .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(max(30%, 230px)), 1fr));
  grid-gap: 1rem;
}

.card-section-2col .sectionbody,
.card-section-2col .content {
  grid-template-columns: repeat(auto-fill, minmax(calc(max(50% - 0.5rem, 250px)), 1fr));
}

.card-section .sectionbody> :not(.card) {
  grid-column: 1 / -1;
}

.card {

  .content,
  .paragraph,
  p {
    display: inline;
  }
}

.card a {
  display: block;
  height: 100%;
  width: 100%;
}

/* inner card layout */

.card a {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.2rem;

  .card-body {
    flex-grow: 1;
  }
}

.card-title {
  display: flex;
  vertical-align: center;
}

.card-body {
  display: block;
}

/* styling */

.card a {
  border-radius: var(--border-radius);
  color: inherit;
  text-decoration: none;
  font-size: .9rem;

  .card-title {
    font-family: var(--heading);
    font-weight: var(--weight-bold);
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1.2rem;
  }
}

/* styling learn */

.card-learn a {
  border: solid 1px #cacbcc;
  border-left: solid 3px #53536B;
  color: var(--color-text-light);

  .card-title {
    margin-bottom: .8rem;
  }

  &:hover {
    border-color: #B8BFF4;
    border-left-color: #4E5EE4;
    box-shadow: 0 6px 12px 0 rgba(60, 60, 60, 0.09);

    .card-title {
      color: #4E5EE4;
    }
  }
}

/* styling product cards */

.card-primary a,
.card-secondary a {
  box-shadow: 0 5px 10px 0 rgba(60, 60, 60, 0.1);

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(60, 60, 60, 0.3);
  }

  .card-title::before {
    content: "";
    background-image: var(--card-icon);
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    vertical-align: middle;
    margin-right: .6em;
  }
}

.card-primary a {
  min-height: 14rem;
  color: white;
  position: relative;
  padding-top: 3em;
  padding-bottom: 2em;
  overflow: hidden;
  /* hide background image */

  .card-title {
    font-size: 1.2em;
  }

  &::before {
    display: block;
    width: 95px;
    height: 70px;
    position: absolute;
    bottom: 30px;
    right: -20px;
    content: "";
    background-image: url(../images/cards.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    content: "Get started >";
    font-family: var(--heading);
    font-weight: var(--weight-bold);
    font-size: .8em;
    text-transform: uppercase;
    letter-spacing: .05em;
  }
}

.card-secondary a {
  border: solid 1px #e9e9ed;
  color: var(--color-text-light);

  &:hover {
    border-color: #dfdfe0;
  }
}

.card-contracts a {
  --card-icon: url(../images/contracts_white.svg);
  background-image: linear-gradient(45deg, #6746d6 0%, #a134d1 100%);
}

.card-defender a {
  --card-icon: url(../images/defender_white.svg);
  background-image: linear-gradient(-135deg, #00e1d4 0%, #00c7f2 100%);
}

.card-community {
  --card-icon: url(../images/icons/community-contracts.svg);
}

.card-upgrades {
  --card-icon: url(../images/icons/upgrades-plugins.svg);
}

.card-contracts-cairo {
  --card-icon: url(../images/icons/contracts-cairo.svg);
}

.card-contracts-stylus {
  --card-icon: url(../images/icons/contracts-stylus.svg);
}

.card-stellar-contracts {
  --card-icon: url(../images/icons/stellar-contracts.svg);
}

.card-uniswap-hooks {
  --card-icon: url(../images/icons/starter-kits.svg);
}

.card-substrate-runtimes {
  --card-icon: url(../images/icons/substrate-runtimes.svg);
}

.card-test-environment {
  --card-icon: url(../images/icons/test-environment.svg);
}

.card-network-js {
  --card-icon: url(../images/icons/network-js.svg);
}

.card-gsn-helpers {
  --card-icon: url(../images/icons/gsn-helpers.svg);
}

.card-gsn-provider {
  --card-icon: url(../images/icons/gsn-provider.svg);
}

.card-contract-loader {
  --card-icon: url(../images/icons/contract-loader.svg);
}

.card-solidity-docgen {
  --card-icon: url(../images/icons/docgen.svg);
}

.card-subgraphs {
  --card-icon: url(../images/icons/subgraphs.svg);
}
